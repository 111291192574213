define("discourse/plugins/stemaway-ui-addons/discourse/templates/connectors/user-main-nav/aivia-analytics-nav-item", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if currentUser.admin}}
    {{user-nav-item
      model=model
      icon="building"
      label=(i18n "stemaway_ui_addons.aivia_analytics.aivia_analytics_title")
      link="aivia-analytics"
      restricted=false
    }}
  {{/if}}
  
  */
  {
    "id": "oNrhycHy",
    "block": "[[[41,[30,0,[\"currentUser\",\"admin\"]],[[[1,\"  \"],[1,[28,[35,1],null,[[\"model\",\"icon\",\"label\",\"link\",\"restricted\"],[[30,0,[\"model\"]],\"building\",[28,[37,2],[\"stemaway_ui_addons.aivia_analytics.aivia_analytics_title\"],null],\"aivia-analytics\",false]]]],[1,\"\\n\"]],[]],null],[1,[28,[32,0],[\"[[\\\"The `currentUser` property path was used in the `discourse/plugins/stemaway-ui-addons/discourse/templates/connectors/user-main-nav/aivia-analytics-nav-item.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.currentUser}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `model` property path was used in the `discourse/plugins/stemaway-ui-addons/discourse/templates/connectors/user-main-nav/aivia-analytics-nav-item.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"if\",\"user-nav-item\",\"i18n\"]]",
    "moduleName": "discourse/plugins/stemaway-ui-addons/discourse/templates/connectors/user-main-nav/aivia-analytics-nav-item.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});