define("discourse/plugins/stemaway-ui-addons/discourse/controllers/aivia-analytics", ["exports", "@ember/controller", "@ember/service", "@ember/object"], function (_exports, _controller, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AiviaAnalyticsController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.inject]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    isMainRoute = true;
    constructor() {
      super(...arguments);
      this.router.on("routeDidChange", this.handleRouteChange.bind(this));
    }
    handleRouteChange() {
      this.set("isMainRoute", this.router.currentRouteName === "aivia-analytics.index");
    }
    static #_2 = (() => dt7948.n(this.prototype, "handleRouteChange", [_object.action]))();
    transitionTo(routeName, event) {
      event.preventDefault();
      if (routeName) {
        console.log("Navigating to route:", routeName);
        this.router.transitionTo(routeName);
      } else {
        console.error("Route name is undefined");
      }
    }
    static #_3 = (() => dt7948.n(this.prototype, "transitionTo", [_object.action]))();
  }
  _exports.default = AiviaAnalyticsController;
});